import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
} from '../ui-components';




const PitchDeckPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html;
  

  return (
    <>
      <SEO
        canonical="/faq/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>
          <PageTitle>{content.title}</PageTitle>
          <br />
          <iframe style={{"width": "100%", "maxWidth": "960px", "height": "100%", "maxHeight": "600px", "paddingBottom": "100px"}} src={content.url} frameborder="0" allowfullscreen=""></iframe>
          <br />
          <br />
        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default PitchDeckPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/pitch-deck.md/"}) {
    frontmatter {
      title
      url
    }
    html
  }
}  
`;